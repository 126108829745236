import './Modal.scss'
import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { GoPlus } from 'react-icons/go';
const CommonModal = (props) => {
  const {btnLabel,context,onClick,title,formId} = props;
  const [open, setOpen] = useState(false);
  // const [form, setFormId] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    onClick();
    // setFormId(formId);
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Button type="primary" onClick={showModal}>
      <GoPlus/> {btnLabel}
      </Button>
      <Modal
        title={title}
        open={open}
        onOk={handleOk}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" htmlType='submit' value='submit' form={formId} loading={confirmLoading} onClick={handleOk}>
            Submit
          </Button>,
        ]}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <div>{context}</div>
      </Modal>
    </>
  );
};
export default CommonModal;