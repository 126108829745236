import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber,
  Cascader,
  TreeSelect,
  Button,
  Checkbox,
  Radio,
  message,
  Space
} from "antd";
import "./Ant-FormBuilder.scss";
import { CaretDownOutlined, InfoCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
// import { DEPLOYED_ENVIRONMENT, ENVIRONMENTS } from "../../../Environments/environments";
import { Option } from "antd/lib/mentions";

// Form supports fields of type
// 1. Text
// 2. TextArea
// 3. Number
// 4. Password
// 5. Single Select
// 6. Multi Select
// 7. Cascader
// 8. TreeSelect
// 9. DatePicker
// 10. Checkbox
// 11. Radio

export default function FormBuilder({ config, onAction, data, id, resetOnSubmit, disable, ...props }) {
  const [form] = Form.useForm();
  const { SHOW_CHILD } = Cascader;
  const formLayout = "vertical";
  const { TextArea } = Input;
  const [dataForm, setDataForm] = useState();

  useEffect(() => {
    if (data) {
      let tempData = JSON.parse(JSON.stringify(data));
      let formData = {};
      config.map((row) =>
        row.map((el) => {
          if (el.key) {
            formData[el.key] = tempData[el.key];
          }
        })
      );
      setDataForm({ ...formData });
      form.setFieldsValue({ ...formData });
    }
  }, [data]);

  /**
   * On input value changes
   * @param {*} e - change event
   * @param {*} field - form field
   */
  const onSelectChange = (e, field) => {
    try {
      if (field?.key === "project_code_id") {
        let clientName = "";
        field?.options.map((option) => {
          if (option?.value === e) {
            clientName = option?.client_name;
          }
        });
        let tempData = data;
        tempData["project_code_id"] = e;
        tempData["client_name"] = clientName;
        form.setFieldsValue({ ...tempData });
        const payload = {
          key: "project_code_id",
          value: e,
        };
        payload["data"] = { ...tempData };
        if (typeof onAction === "function")
          onAction({ type: "onChange", payload });
      } else if (field?.key === "action") {
        if (typeof onAction === "function") onAction({ type: "onSelect", payload: { key: field?.key, data: { [field?.key]: e } }, formId: id });
      }
    } catch (error) {
      console.log("error", error);
    }
  };


  const onFormLayoutChange = (changedValues, allValues) => {
    const payload = {};
    payload["key"] = Object.keys(changedValues)[0];
    payload["data"] = { ...data, ...changedValues };
    payload["allData"] = { ...data, ...allValues }
    if (typeof onAction === "function") onAction({ type: "onChange", payload, formId: id });
  };

  /**
   * Finish event of form
   * @param {*} formData - form data
   */
  const onFinish = (formData) => {
    let errors = {}
    if (Object.keys(errors).length === 0) {
      try {
        const payload = {
          data: formData,
        };
        if (typeof onAction === "function") onAction({ type: "submit", payload, formId: id });
        if (resetOnSubmit) form.resetFields();
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const infoClicked = (el) => {
    if (typeof onAction === "function") onAction({ type: "infoClick", payload: { clickedField: el }, formId: id });
  }


  const handleAddSelect =(ele)=>{
    if (typeof onAction === "function") onAction({ type: "add-select", payload:{key:ele?.key , value:ele?.value}, formId: id });
  }

  const uploadProps = {
    name: 'file',
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info, info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`, 3);

      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`, 3);
      }
    }
  }

  const search = (array) => array?.find(element => element?.required === true);

  return (
    <Form
      id={id}
      form={form}
      layout={formLayout}
      onFinish={onFinish}
      onValuesChange={onFormLayoutChange}
      className="form-builder"
      name={id}
      disabled={disable}
      // preserve={false}
    >
      {config?.map((list, i) => {
        return (<Row id={i} className="form-row">
          {list?.map((field, i) => {
            return (
              <Col md={field?.col} lg={field?.col} sm={24} xs={24} span={field?.col} >
                {field?.type && (
                  <>
                    <div className="form-question">
                      <label className="field-label" style={field?.style}>
                        {field?.label}
                        {search(field?.rules)?.required && !field?.disableAsterisk && (
                          <span className="required-star"> *</span>
                        )}
                        {field.optional && (
                          <span className="optional">(optional)</span>
                        )}
                      </label>
                      <span>{field?.info && (
                        <Button className="info-button" type="link" icon={<InfoCircleOutlined />} onClick={() => infoClicked(field)} ghost>{field?.info}</Button>
                      )}</span>
                    </div>
                  </>
                )}
                {field?.type && field?.type === "input" && (
                  <>
                    <Form.Item name={field?.key} rules={field?.rules} validateStatus={field?.validateStatus} help={field?.help}>
                      <Input
                        placeholder={field?.placeholder}
                        disabled={field?.disabled}
                        autoComplete={field?.autoComplete || 'off'}
                      // validateStatus={validateEmptyField(name) ? 'success' : 'error'}

                      />
                    </Form.Item>
                  </>
                )}
                {field?.type && field?.type === "button" && (
                  <>
                    <Form.Item name={field?.key} rules={field?.rules}>
                      <Button
                        className="submit-btn"
                        type={field?.variant}
                        htmlType={field?.submit ? "submit" : "button"}
                        onClick={() => { onFinish({ key: field?.key }) }}
                        disabled={field?.disabled}
                      >{field?.title}</Button>
                    </Form.Item>
                  </>
                )}
                {field?.type && field?.type === "select" && (
                  <>
                    <Form.Item name={field?.key} rules={field?.rules}>
                      <Select
                        placeholder={field?.placeholder}
                        suffixIcon={<CaretDownOutlined style={{ pointerEvents: "none" }} />}
                        onChange={(e) => onSelectChange(e, field)}
                        getPopupContainer={trigger => trigger.parentNode}
                        showSearch
                        filterOption={(input, option) =>
                          option.label.toLowerCase().includes(input.toLowerCase())
                        }
                        options={field?.options}
                        disabled={field?.disabled}
                        maxTagCount={field?.maxTagCount}
                        loading={field?.loading}
                        autoComplete={field?.autoComplete || 'off'}
                      ></Select>
                    </Form.Item>
                  </>
                )}
                {field?.type && field?.type === "styled-select" && (
                  <>
                    <Form.Item name={field?.key} rules={field?.rules}>
                      <Select
                        placeholder={field?.placeholder}
                        suffixIcon={<CaretDownOutlined style={{ pointerEvents: "none" }} />}
                        onChange={(e) => onSelectChange(e, field)}
                        getPopupContainer={trigger => trigger.parentNode}
                        showSearch
                        filterOption={(input, option) =>
                          option.label.toLowerCase().includes(input.toLowerCase())
                        }
                        disabled={field?.disabled}
                        maxTagCount={field?.maxTagCount}
                        loading={field?.loading}
                        autoComplete={field?.autoComplete || 'off'}

                      >
                        {field?.options?.map(el => {
                          return <Option value={el?.value} style={{...el?.style}} ><div style={{paddingLeft:7, borderRadius:5,...(el?.color_code && {backgroundColor:el?.color_code})}}>{el.label}</div></Option>
                        })}
                      </Select>
                    </Form.Item>
                  </>
                )
                }
                {field?.type && field?.type === "select-clicked" && (
                  <>
                    <Form.Item rules={field?.rules}>
                      <Select
                        placeholder={field?.placeholder}
                        suffixIcon={<CaretDownOutlined style={{ pointerEvents: "none" }} />}
                        onSelect={(e) => onSelectChange(e, field)}
                        showSearch
                        filterOption={(input, option) =>
                          option.label.toLowerCase().includes(input.toLowerCase())
                        }
                        options={field?.options}
                        disabled={field?.disabled}
                        maxTagCount={field?.maxTagCount}
                        loading={field?.loading}
                        autoComplete='off'
                      ></Select>
                    </Form.Item>
                  </>
                )}
                {field?.type && field?.type === "multi-select" && (
                  <>
                    <Form.Item name={field?.key} rules={field?.rules}>
                      <Select
                        placeholder={field?.placeholder}
                        suffixIcon={<CaretDownOutlined style={{ pointerEvents: "none" }} />}
                        getPopupContainer={trigger => trigger.parentNode}
                        optionFilterProp="label"
                        showSearch
                        filterOption={(input, option) =>
                          option.label.toLowerCase().includes(input.toLowerCase())
                        }
                        mode="multiple"
                        showArrow={true}
                        options={field?.options}
                        disabled={field?.disabled}
                        maxTagCount={field?.maxTagCount || 'responsive'}
                        loading={field?.loading}
                        autoComplete="none"
                        dropdownRender={menu => (
                          <>
                            {menu}  
                            {field?.extraButton && 
                              <Button type="link" icon={<PlusOutlined />} onClick={()=>handleAddSelect({key:field?.key, value:field?.extraButton?.value})} block>
                              {field?.extraButton?.title}
                              </Button>
                            }
                          </>
                        )}
                      ></Select>
                    </Form.Item>
                  </>
                )}
                {field?.type && field?.type === "date-picker" && (
                  <>
                    <Form.Item name={field?.key} rules={field?.rules}>
                      <DatePicker
                        style={{ width: "100%", ...(field?.borderless && { padding: '4px 2px', color: 'black', fontWeight: 600 }) }}
                        format={field?.format || 'DD MMMM YYYY'}
                        placeholder={field?.placeholder}
                        disabled={field?.disabled}
                        disabledDate={d => (!d || d.isBefore(field?.minDate || "") || d.isAfter(field?.maxDate || ""))}
                        onChange={(e) => onSelectChange(e, field)}
                        allowClear={false}
                        autoComplete='off'
                        bordered={!field?.borderless}
                      />
                    </Form.Item>
                  </>
                )}
                {field?.type && field?.type === "inputNumber" && (
                  <>
                    <Form.Item name={field?.key} rules={field?.rules}>
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder={field?.placeholder}
                        disabled={field?.disabled}
                        min={1}
                        controls={false}
                        autoComplete="off"
                      />
                    </Form.Item>
                  </>
                )}
                {field?.type && field?.type === "Select-Cascader" && (
                  <Form.Item name={field?.key} rules={field?.rules}>
                    <Cascader
                      style={{ width: "100%" }}
                      placeholder={field?.placeholder}
                      maxTagCount={field?.maxTagCount}
                      getPopupContainer={trigger => trigger.parentNode}
                      options={field?.options}
                      multiple={field?.type === "Select_Multi_Cascader"}
                      showSearch={true}
                    />
                  </Form.Item>
                )}
                {field?.type && field?.type === "tree-select" && (
                  <>
                    <Form.Item name={field?.key} rules={field?.rules}>
                      <TreeSelect
                        placeholder={field?.placeholder}
                        disabled={field?.disabled}
                        hideSelectedOptions={field?.hideSelectedOption}
                        showArrow
                        style={{ width: "100%" }}
                        treeData={field?.options}
                        treeCheckable={true}
                        allowClear={true}
                        labelInValue={true}
                        showSearch={true}
                        showCheckedStrategy={SHOW_CHILD}
                        menuPosition="fixed"
                        maxTagCount={field?.maxTagCount}
                        closeMenuOnSelect={field?.closeMenuSelect}
                      />
                    </Form.Item>
                  </>
                )}
                {field?.type && field?.type === "textArea" && (
                  <Form.Item name={field?.key} rules={field?.rules}>
                    <TextArea
                      rows={3}
                      maxLength={500}
                      placeholder={field?.placeholder}
                      disabled={field?.disabled}
                      autoComplete='off'
                    />
                  </Form.Item>
                )}
                {field?.type && field?.type === "password" && (
                  <Form.Item name={field?.key} rules={field?.rules} validateStatus={field?.validateStatus} help={field?.help}>
                    <Input.Password
                      placeholder={field?.placeholder}
                      disabled={field?.disabled}
                      current-password=""
                      autoComplete='off'
                    />
                  </Form.Item>
                )}
                {field?.type && field?.type === "checkbox" && (
                  <Form.Item name={field?.key} rules={field?.rules}>
                    <Checkbox.Group >
                      <Space direction={field?.direction}>
                        {field?.options.map((option) => (
                          <Checkbox value={option?.value}>{option?.label}</Checkbox>
                        ))}
                      </Space>
                    </Checkbox.Group>
                  </Form.Item>
                )}
                {field?.type && field?.type === "radio" && (
                  <Form.Item name={field?.key} rules={field?.rules}>
                    <Radio.Group>
                      <Space direction={field?.direction}>
                        {field?.options.map((option) => (
                          <Radio value={option?.value}>{option?.label}</Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                )}
                {field?.type && field?.type === "radioButton" && (
                  <Form.Item name={field?.key} rules={field?.rules}>
                    <Radio.Group size={field?.size || 'small'} disabled={field?.disabled}>
                      <Space>
                        {field?.options.map((option) => (
                          <Radio.Button value={option?.value} >{option?.label}</Radio.Button>
                        ))}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                )}
              </Col>
            );
          })}
        </Row>
        )
      })}
      {/* <Button className="submit-btn" type="primary" htmlType="submit"></Button> */}
    </Form >
  );
}
