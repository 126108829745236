import { VARIABLES } from "../../Environments/constants";
import { ServiceUtils } from "./ServiceUtils";
import { sessionService } from "./SessionService";
import { alertService } from "./ToasterUtils";

function titleCase(text) {
  const input = text || '';
  const temp = input.replaceAll('-', ' ');
  const ar = temp.split(' ').map(el => {
    let t = '';
    t += el.charAt(0).toUpperCase();
    t += el.substr(1);
    return t;
  })
  return ar.join(' ');
}

function htmlToText(content) {
  let tag = document.createElement('div');
  tag.id = 'text2html';
  tag.innerHTML = content;
  return tag.innerText;
}

function isEmpty(value) {
  return value === null || value === undefined || value === '' || (typeof value === 'string' && value.trim() === '');
};

function fetchList(key, callback) {
  ServiceUtils.getRequest(`list-${key}`).then(
    _response => {
      if (_response.status === 200) {
        if (_response.data.status === 'Success') {
          if (typeof callback === 'function') callback(_response.data)
        } else {
          alertService.error(_response.data.message || `Error while fetching ${key} list`);
        }
      }else if(_response.status === 401) alertService.warning('Session Expired !') 
      else {
        alertService.error(_response?.data?.message || _response.message || 'Error while fetching list.');
      }
    }
  );
}


function fetchListPost(key, payload, callback , error ,showLoader = true) { 
  ServiceUtils.postRequest(key, payload , showLoader).then(
    _response => {
      if (_response.status === 200) {
        if (_response.data.status === 'Success') {
          if (typeof callback === 'function') callback(_response.data)
        } else {
          alertService.error(_response.data.message || `Error while fetching list`);
        }
      }else if(_response.status === 401) alertService.warning('Session Expired !') 
      else {
        if (typeof error === 'function') error(_response?.data?.message || _response.message || `Error while fetching list`);
        alertService.error(_response?.data?.message || _response.message || `Error while fetching list`);
      }
    }
  );
}

function updateFormConfig(configObj, key, meta) {
  return configObj.map(row => row.map(formEl => {
    if (formEl.key === key || formEl.value === key) return { ...formEl, ...meta };
    return formEl;
  }))
}

// function updateValueConfig(configObj, value, meta) {
//   return configObj.map(formEl => {
//     if (formEl.value === value) return { ...formEl, ...meta };
//     return formEl;
//   })
// }

function getFormConfig(configObj, key) {
  let tempConfig;
  configObj.map(row => row.map(formEl => {
    if (formEl.key === key) tempConfig = { ...formEl };
  }))
  return tempConfig;
}
const checkAccess = access => { // ['admin', 'lead']
  const role = sessionService.getSession()?.[VARIABLES.userRole]; // ['Admin', 'Lead']
  return (Array.isArray(access) && Array.isArray(role) && role?.filter(el => access?.includes(el?.toLowerCase()))?.length > 0);
}

function deepClone(object) {
  return JSON.parse(JSON.stringify(object));
}

function searchTable(searchText, body, header) {
  let searchColumns = [];
  if (Array.isArray(header)) {
    searchColumns = header.map(el => el?.key);
  }
  const result = body.filter(row =>
    searchColumns.filter(key => row?.[key]?.toString()?.toLowerCase()?.includes(searchText?.toString()?.toLowerCase()) )?.length > 0
  );
  return result;
}

function generateArrayOfYears(min) {
  let max = new Date().getFullYear();
  let years = [];

  for (let i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}


function randomColor(str, saturation = 57, brightness = 61  ) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var h = hash % 360;
  return 'hsl('+h+', '+saturation+'%, '+brightness+'%)';
}

const avtarName =(_name)=>{
  try{
  let nameSplit = _name?.split(" ");
  return (nameSplit[0]?.charAt(0)?.toUpperCase()||'') + (nameSplit[1]?.charAt(0)?.toUpperCase()||'');
  }catch(e){
    console.log(e);
  }
}


export {
  titleCase,
  htmlToText,
  fetchList,
  fetchListPost,
  isEmpty,
  updateFormConfig,
  getFormConfig,
  deepClone,
  checkAccess,
  searchTable,
  generateArrayOfYears,
  randomColor,
  avtarName
};