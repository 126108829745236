import { API_ENDPOINTS, API_ENVIRONMENT } from "../../Environments/config";
import { MESSAGES } from "../../Environments/constants";
import {
  BASE_PATH,
  DEPLOYED_ENVIRONMENT,
  ENVIRONMENTS,
} from "../../Environments/environments";
import axios from "axios";
import { loaderService } from "./Loader/Loader";
import { alertService } from "./ToasterUtils";
import { BehaviorSubject } from "rxjs";

const loaderSubject = new BehaviorSubject();

axios.interceptors.request.use((request) => {
  const loader = loaderSubject.getValue();
  if (loader) loaderService.showLoader();
  return request;
});
axios.interceptors.response.use(
  (response) => {
    const loader = loaderSubject.getValue();
    if (loader) loaderService.hideLoader();
    return response || {};
  },
  ({ response }) => {
    // Error
    const loader = loaderSubject.getValue();
    if (loader) loaderService.hideLoader();
    if (response?.status === 401 || !response) {
      alertService.error(MESSAGES.invalidToken);
      const path = window.location.href.split("#");
      window.location.href = path[0] + "#/logout";
    } else {
      console.log(
        "ERROR " + response?.status + ":",
        response?.config?.url?.split("klapps")?.[1],
        response?.data
      );
    }
    return response || {};
  }
);

const get = (urlKey, showLoader = true) => {
  let url;
  const environment = API_ENVIRONMENT[urlKey] || DEPLOYED_ENVIRONMENT;
  if (environment === "local") {
    url = BASE_PATH + `/Assets/json/${urlKey}.json`;
  } else {
    url = ENVIRONMENTS[environment].baseurl + API_ENDPOINTS[urlKey];
  }
  loaderSubject.next(showLoader);

  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  };

  return axios.get(url, config);
};

const post = (urlKey, params, showLoader = true, cancelToken = undefined) => {
  const environment = API_ENVIRONMENT[urlKey] || DEPLOYED_ENVIRONMENT;
  if (environment === "local") return get(urlKey);
  let url = ENVIRONMENTS[environment].baseurl + API_ENDPOINTS[urlKey];
  loaderSubject.next(showLoader);

  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  };

  if (cancelToken !== undefined) {
    config.cancelToken = cancelToken;
  }

  return axios.post(url, params, config);
};

const ServiceUtils = {
  getRequest: get,
  postRequest: post,
};
export { ServiceUtils };
