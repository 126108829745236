import React, { useState, useCallback, useEffect } from "react";
import "./Login.scss";
import { Row, Col, Button } from "antd";
import { sessionService } from "../../Shared/Utils/SessionService";
import { VARIABLES } from "../../Environments/constants";
import LoginBg from "../../Assets/images/login_bg.webp";
import { Image } from "antd";
import userLoginJson from "../../Assets/jsons/UserLogin.json";
import AntBuilder from "../../Shared/Components/Ant-FormBuilder/Ant-FormBuilder";
import { ServiceUtils } from "../../Shared/Utils/ServiceUtils";
import { alertService } from "../../Shared/Utils/ToasterUtils";
import { updateFormConfig } from "../../Shared/Utils/CommonUtils";
import { useNavigate } from "react-router-dom";
export default function Login(props) {
  const navigate = useNavigate();  
  const [credential, setCredential] = useState({
    user: "admin",
    password: "admin@123",
  });

  const [timer, setTimer] = useState(5);
  const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);
  const [formConfig, setFormConfig] = useState(userLoginJson);



  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

 
  const handleLogin = (action) => {
    if (action?.type === "onChange") {
      let tempConfig = [...formConfig];
      tempConfig = updateFormConfig(tempConfig, "user", { validateStatus: "", help: "" })
      tempConfig = updateFormConfig(tempConfig, "password", { validateStatus: "", help: "" })
      setFormConfig(tempConfig);
      setCredential({ ...credential, ...action.payload.data });
    }
    if (action?.payload?.data?.key === "sign-in") {
        navigate('/dashboard')
      ServiceUtils.postRequest("userLogin", credential).then(
        (response) => {
          if (response.status === 200 && response?.data.status === "success") {
            const session = {
              [VARIABLES.userName]: response?.data?.data?.name,
              [VARIABLES.email]: response?.data?.data?.email,
            };
            let role;
            try {
              role =
                response?.data?.data?.role?.map((el) => el?.toLowerCase()) ||
                [];
            } catch (e) {
              role = [];
            }
            session[VARIABLES.userRole] = role;
            sessionService.setSession(session);
            sessionService.setSession(VARIABLES.menuToggle, "false");
            props.updateAuth(session);
            console.log('%c Hii, Welcome to%c Micro Parameters', 'color:#082c7c;font-size:30px;text-shadow: 1px 1px 1px #082c7c9c;', 'color:#082c7c;font-size:36px; font-weight:600;text-shadow: 1px 1px 1px #082c7c9c; ');
          } else {
            // sessionService.clearSession('sso-redirect');
            alertService.error(
              response?.data?.message || "Error while fetching user role."
            );
            let tempConfig = [...formConfig];
            let responseData = response?.data?.message;
            if (responseData) {
              tempConfig = updateFormConfig(tempConfig, "user", { validateStatus: "error", help: "" })
              tempConfig = updateFormConfig(tempConfig, "password", { validateStatus: "error", help: responseData || "Invalid" })
            }
          setFormConfig(tempConfig);
        }
        },
    (error) => {
      alertService.error("Error while fetching user role.");
      console.log("userLogin Error", error);
    }
      );
}




  };
return (
  <div className="login-root">
    <div className="mob-tab-header"><p className="header-cnt">Micro Parameters</p></div>
    <Row className="main-container">
      <Col span={17} className="left-content">
        <Image
          rootClassName="bg-image"
          height="102%"
          src={LoginBg}
          preview={false}
          alt="Login"
          className="image-tab"
        />
        <div className="top-left">Micro Parameters</div>
      </Col>

      <Col span={7} className="right-content">
        <Row span={18} className="login-content">
          <div className="formCenter">
            <h1 className="header">Micro Parameters</h1>
            <div className="subHeader">Sign In to your account</div>
            <AntBuilder
              config={formConfig}
              id='user_login'
              onAction={handleLogin}
              data={credential}
            />
            <Button className="right-btn" type="link">Forgot Password?</Button>
          </div>

        </Row>
      </Col>

    </Row>
  </div>
);
}

