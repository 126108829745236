import React from "react";
import ReactEcharts from "echarts-for-react";

export default function EChart(props) {
    const { chartOptions } = props;
    return (
        <>
            <ReactEcharts option={chartOptions} />
        </>
    )
}