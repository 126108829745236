import './App.scss';
import Loader from '../src/Shared/Utils/Loader/Loader'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation 
} from "react-router-dom";
import Dashboard from './Pages/Dashboard/Dashboard';
import NavBar from './Shared/Components/NavBar/Navbar';
import AddUser from './Pages/User/AddUser';
import Login from './Pages/Login/Login'
import CreateEntry from './Pages/MicroEntry/CreateMicroEntry/CreateEntry';
import ListEntry from './Pages/MicroEntry/ListMicroEntry/ListEntry';
import AddDivision from './Pages/Division/Division';
import AddCategory from './Pages/Category/AddCategory';
import AddParameter from './Pages/Parameter/AddParameter';
import AddPlant from './Pages/Plant/AddPlant';

function App() {
  const ConditionalNavBar = () => {
    const location = useLocation();
    const hideNavBarPaths = ['/']; // Add more paths if needed
  
    return hideNavBarPaths.includes(location.pathname) ? null : <NavBar />;
  };
  
  return (<>
    <Loader />
    <Router>
    <ConditionalNavBar />
      <Routes>
        <Route path="/" element={
          <Login />
        } />
        <Route path="/dashboard" element={
          <Dashboard />
        } />
        <Route path="add_user" element={
          <AddUser/>
        }/>
        <Route path="add_division" element={
          <AddDivision />
        }/>
        <Route path="add_category" element={
          <AddCategory />
        }/>
        <Route path="add_parameter" element={
          <AddParameter />
        }/>
        <Route path="add_plant" element={
          <AddPlant />
        }/>
        <Route path="createMicroEntry" element={
          <CreateEntry/>
        }/>
        <Route path="microParameterEntry" element={
          <CreateEntry/>
        }/>
        <Route path="microParameterEntry/list_category_reading" element={
          <ListEntry/>
        }/>
      </Routes>
    </Router>
  </>
  );
}

export default App;
