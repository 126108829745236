import React, { useState } from 'react';
import FormBuilder from "../../Shared/Components/Ant-FormBuilder/Ant-FormBuilder";
import usersConfig from '../../Assets/jsons/user.json'
import { ServiceUtils } from "../../Shared/Utils/ServiceUtils";
import { Button, Flex, Form, Input, InputNumber, Popconfirm, Table, Typography } from 'antd';
import CommonModal from '../../Shared/Components/Modal/Modal';
import { updateFormConfig } from '../../Shared/Utils/CommonUtils';
import './AddUser.scss'


const originData = [];
for (let i = 0; i < 100; i++) {
    originData.push({
        key: i.toString(),
        user_name: `Edward ${i}`,
        mobile_num: 32,
        reg_num:33,
        address: `London Park no. ${i}`,
        role:'admin'
    });
}
const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
const AddUser = () => {
    const [form] = Form.useForm();
    const [config, setConfig] = useState(usersConfig);
    const [formSubmit,setFormSubmit] = useState();
    const [userData, setUserData] = useState({});
    const [data, setData] = useState(originData);
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
        form.setFieldsValue({
            name: '',
            mobile_num: '',
            reg_num: '',
            address: '',
            role: '',
            ...record,
        });
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
    };
    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };
    const columns = [
        {
            title: 'User Name',
            dataIndex: 'user_name',
            width: '15%',
            editable: true,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_num',
            width: '15%',
            editable: true,
        },
        {
            title: 'Reg Number',
            dataIndex: 'reg_num',
            width: '15%',
            editable: true,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            width: '30%',
            editable: true,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            width: '10%',
            editable: true,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.key)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        Edit
                    </Typography.Link>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'mobile_num' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    const modalBody = () => (
        <FormBuilder config={config} data={userData} id="user_form" onAction={handleFormAction} />
    );
    const handleFormAction = (action) => {
        try {
            setFormSubmit('user_form')
            if (action?.type === "onChange") {
                let data = [...config];
                data = updateFormConfig(data, 'user_name', { validateStatus: "", help: "" });
                data = updateFormConfig(data, 'user_mobile', { validateStatus: "", help: "" });
                data = updateFormConfig(data, 'user_regNo', { validateStatus: "", help: "" });
                data = updateFormConfig(data, 'user_address', { validateStatus: "", help: "" });
                data = updateFormConfig(data, 'user_role', { validateStatus: "", help: "" });
                setConfig(data);
                setUserData({ ...data, ...action?.payload?.data })
            } else if (action?.type === "submit") {
                const payload = action?.payload?.data;
                ServiceUtils.postRequest('save', { payload }).then(res => {
                    console.log("Response", res);
                });
            }
            console.log("Actions", action);

        } catch (error) {
            console.error(error);
        }
    }
    return (
        <div className='mainContent'>
            <h3 className='subHeader'> User Management</h3>
            <Flex justify='flex-end' align='flex-end' className='m-2'>
                <CommonModal
                btnLabel = 'Add User'
                context= {modalBody()}
                title="Add New User"
                onClick={handleFormAction}
                formId={formSubmit}
                />
            </Flex>
            {/* Table  */}
            <Form form={form} component={false}>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    bordered
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{
                        onChange: cancel,
                    }}
                />
            </Form>
        </div>
    );
};
export default AddUser;