export const ENVIRONMENTS = {
    localhost: {
      baseurl: '/api/v1.0/micro_param/',
    },
    dev: {
      baseurl: 'https://jsonplaceholder.typicode.com/',
    },
  };
  
  export const DEPLOYED_ENVIRONMENT = 'dev';
  export const BASE_PATH = '/micro_param';
  
