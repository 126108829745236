import React, { useEffect, useState } from "react";
import { Card, Col, Row } from 'antd';
import { ServiceUtils } from "../../Shared/Utils/ServiceUtils";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, ArcElement } from 'chart.js';
import './Dashboard.scss'
import EChart from "../Charts/EBar";
import TableConfig from '../../Assets/jsons/tableTest.json'
import TableBuilder from "../../Shared/Components/Ant-TableBuilder/Ant-TableBuilder";

// Register the necessary components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, ArcElement);
function Dashboard() {
    const [formData, setFormData] = useState({});
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });
    useEffect(() => {
        testFun();
        getChartData();
    }, []);

    const getChartData = () => {
        setChartData({
            labels: [
                'Total No of Plants',
                'Total no of users',
                'Total no of parameters'
            ],
            datasets: [
                {
                    data: [6175, 1845, 1530],
                    //backgroundColor:'green',
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.6)',
                        'rgba(54, 162, 235, 0.6)',
                        'rgba(255, 206, 86, 0.6)',
                        'rgba(75, 192, 192, 0.6)',
                        'rgba(153, 102, 255, 0.6)',
                        'rgba(255, 159, 64, 0.6)',
                        'rgba(255, 99, 132, 0.6)'
                    ]
                }
            ]
        });
    };

    // ECHARTS 
    // Bar
    const option = {
        grid: {
            height: "auto",
            width: "auto"
          },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: 'bar'
          }
        ]
      };
      //Line 
      const lineOption = {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line'
          }
        ]
      };
      //Pie
      const pieOptions = {
        title: {
          text: 'Parameters',
          subtext: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: 'CFU' },
              { value: 735, name: 'CFU/g' },
              { value: 580, name: 'CPU/gg' },
              { value: 484, name: 'Others' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };


    const testFun = () => {
        ServiceUtils.postRequest('posts', { user_name: 'ruthbull' }).then(
            _res => {
                console.log("Res", _res);
                setFormData(_res.user_name)
            }
        )
    }
    return (
        <div className="mainContent">
            <h1 className="header">Overview Dashboard</h1>
            <Row gutter={16}>
                <Col span={8}>
                    <Card title="Last Month Productions" bordered={false} className="my-card">
                        {/* <BarChart chartData={chartData} displayLegend={false} /> */}
                        <EChart chartOptions = {option} />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Last 6 months CF Trend" bordered={false} className="my-card">
                        <EChart chartOptions={lineOption} />
                        {/* <LineChart chartData={chartData} displayLegend={false} displayTitle={true} /> */}
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Last 6 month % Trend" bordered={false} className="my-card">
                        {/* <PieChart chartData={chartData} displayLegend={false} /> */}
                        <EChart chartOptions={pieOptions}/>
                    </Card>
                </Col>
            </Row>
            <h1 className="header mt-2">Manual Category Readings</h1>
            <TableBuilder config={TableConfig} />
        </div>)
}
export default Dashboard;