import React, { useState } from 'react';
import FormBuilder from "../../../Shared/Components/Ant-FormBuilder/Ant-FormBuilder";
import formConfig from '../../../Assets/jsons/createEntryForm.json';
import './CreateEntry.scss'
import { FaRegEye } from "react-icons/fa";
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function CreateEntry() {
    const navigate = useNavigate();
    return (
        <div className='mainContent'>
  <div className='d-flex' style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'space-between' }}>
        <h1 className="subHeader" style={{ flexGrow: 1 }}>Create Micro Parameters Entry</h1>
        <Button type="primary" onClick={()=> navigate('/microParameterEntry/list_category_reading')}><FaRegEye/> View Category Readings</Button>
      </div>
        <FormBuilder config={formConfig} />
        </div>
    )
} 