import React, { useEffect, useState } from 'react';
import './NavBar.scss';
import { Layout } from 'antd';
import { CiUser } from "react-icons/ci";
import { Dropdown, message, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CiMenuBurger } from "react-icons/ci";
import { GoPlus } from "react-icons/go";
import { FaMedium } from "react-icons/fa";

const { Header } = Layout;

const NavBar = () => {
  const navigate = useNavigate();

  const onClick = ({ key }) => {
    if (key === 'logout') {
      navigate('/');
    } else {
      navigate(key);
    }
  };

  const items = [
    {
      "key": "microParameterEntry",
      "label": "Micro Parameter Entry",
      "icon": <GoPlus />
    },
    {
      type: 'divider',
    },
    {
      "key": "add_user",
      "label": "User",
      "icon": <GoPlus />
    },
    {
      "key": "add_category",
      "label": "Category",
      "icon": <GoPlus />
    },
    {
      "key": "add_division",
      "label": "Add Division",
      "icon": <GoPlus />
    },
    {
      "key": "add_parameter",
      "label": "Add Parameter",
      "icon": <GoPlus />
    },
    {
      "key": "add_plant",
      "label": "Add Plant",
      "icon": <GoPlus />
    },
    {
      type: 'divider',
    },
    {
      "key": "logout",
      "label": "Sign Out",
      "icon": <CiUser />
    },

  ];

  return (
    <Layout>
      <Header
        className='d-flex'
        style={{
          position: 'sticky',
          top: 0,
          width: '100%',
          alignItems: 'center',
        }}
      >
        <div className='d-flex subHeader m-2' style={{ flexGrow: 1, alignItems: 'center', }} onClick={() => navigate('/dashboard')}>
          <div className='text-white fs-1'>
            <FaMedium />
          </div>
          <h5 className='text-white nav-title m-4'>Micro Parameters Entry</h5> {/*  'mx-auto' for making text in center  */}
        </div>
        <div className='p-2 fs-3' style={{ filter: 'grayscale(1)' }}>
          <Dropdown
            menu={{
              items,
              onClick,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <CiMenuBurger />
              </Space>
            </a>
          </Dropdown>
        </div>
      </Header>
    </Layout>

  );
};
export default NavBar;