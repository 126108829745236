import { Table } from "antd";
import React, { useEffect, useState } from "react";
export default function TableBuilder({ config, components, ...props }) {
    const [headerContent, setHeaderContent] = useState();
    const [bodyContent, setBodyContent] = useState();

    useEffect(() => {
        if(config){
            tableData();
        }
    }, [config]);

    const tableData = () => {
        const tempBody = config?.bodyContent;
        setBodyContent(tempBody);
        setHeaderContent(mergedColums);
    }
    const mergedColums = config?.headerContent.map((col)=>{
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell:(record) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        }
    });
    return (
        <div>
            <Table
                showHeader={false}
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={bodyContent}
                columns={headerContent}
            />
        </div>
    )
}