export const MESSAGES = {
    server_error: 'The system is currently down due to maintenance. Please try again later.',
    download_url_fail: 'The download action is experiencing some technical difficulties. Please try again in some time',
    filter_no_data: 'No records present for the selected filters',
    table_no_data: 'No records found',
    invalidToken: 'Invalid token. Please login to continue',
    error_500: 'Internal Server Error',
  };
  
  export const VARIABLES = {
    userName: 'name',
    fullName: 'full_name',
    firstName: 'first_name',
    lastName: 'last_name',
    email: 'email',
    menuToggle: 'menu-toggle',
  };
  export const CONSTANTS = {
    tablePageSize: [10, 25, 50, 100, 200, 500],
  };
  export const TOASTER_TIMEOUT = 3000;